'use client'

import { HTMLAttributes, Suspense } from 'react'
import { useTranslations } from 'next-intl'
import { useRouter } from 'next/navigation'
import Button from '@/components/ViverseButton'
import RoomsCarousel from '@/components//RoomsCarousel'
import { EditorChoice } from '@/services/cms'
import { cn } from '@/lib/utils'
import StackCardsContainer from '@/components/StackCardsContainer'
import ActionWorldCard from '@/components/ActionWorldCard'
import { useWindowSize } from 'usehooks-ts'

interface CuratedWorldsProps extends HTMLAttributes<HTMLDivElement> {
  editorChoices: EditorChoice[]
}

function CuratedWorlds({ editorChoices, className, ...props }: CuratedWorldsProps) {
  const t = useTranslations()
  const router = useRouter()
  const { width: windowWidth } = useWindowSize()

  const handleGoToExploreWorlds = () => {
    router.push('/worlds')
  }

  return (
    <section className={cn('flex flex-col 2xl:items-center', className)} {...props}>
      {editorChoices?.map(({ id, title, rooms }) => (
        <Suspense fallback={<RoomsCarousel.Skeleton />} key={`curated-worlds-editor-choice-${id}`}>
          {windowWidth > 1024 ? (
            <RoomsCarousel title={title} rooms={rooms} hideLiked />
          ) : (
            <StackCardsContainer
              titleClassName="subheader-2 mb-3 text-vive-primary-20 lg:header-4 lg:mb-6 lg:text-vive-primary-20"
              title={title}
            >
              {rooms.map((room, index) => (
                <ActionWorldCard
                  className="min-w-[248px] lg:min-w-0"
                  key={`followings-card-${room.id}-${index}`}
                  info={room}
                />
              ))}
            </StackCardsContainer>
          )}
        </Suspense>
      ))}
      <div className="mt-[6px] text-center 2xl:mt-3">
        <Button size="large" variant="secondary" onClick={handleGoToExploreWorlds}>
          {t('landing-page.curated-worlds-cta')}
        </Button>
      </div>
    </section>
  )
}

export default CuratedWorlds
