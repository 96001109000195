import { cn } from '@/lib/utils'
import { HTMLAttributes, ReactNode } from 'react'
import { cva } from 'class-variance-authority'
import Image from 'next/image'

// TODO: refactor the design of the component to the UI driven logic, instead of the data driven logic

type Device = 'mobile' | 'pc'
type Position = 'top' | 'bottom'
type Place = 'lefttop' | 'leftbottom' | 'righttop' | 'rightbottom' | 'masktop' | 'maskbottom'

type Medias = {
  images?: Record<Device, string>
  videos?: Record<
    Device,
    {
      webm: string
      mp4: string
    }
  >
}

export type HeroBanner = Medias & {
  title: string
  description: string
  action?: ReactNode
  className?: string
}

export type HeroSectionConfig = {
  banner: HeroBanner
  infos?: HeroInfoCard[]
}

interface HeroSectionProps extends HTMLAttributes<HTMLDivElement> {
  variant?: 'left' | 'right' | 'mask'
  banner: HeroBanner
  infos?: HeroInfoCardProps[]
}

const heroMaskVariants = cva('absolute z-10 w-full', {
  variants: {
    position: {
      top: 'top-0 bg-[linear-gradient(0deg,_rgba(18,18,22,0)_0%,_rgba(18,18,22,1)_100%)]',
      bottom: 'bottom-0 bg-[linear-gradient(180deg,_rgba(18,18,22,0)_0%,_rgba(18,18,22,1)_100%)]',
    },
    place: {
      lefttop: 'h-[54px] md:h-[96px]',
      leftbottom: 'h-[120px] md:h-[200px]',
      righttop: 'h-[36px] md:h-[96px] lg:h-[120px]',
      rightbottom: 'h-[36px] md:h-[200px] lg:h-[120px]',
      masktop: 'h-[36px] md:h-[80px] lg:h-[96px]',
      maskbottom: 'h-[36px] md:h-[80px] lg:h-0',
    },
  },
  defaultVariants: {
    position: 'top',
    place: 'lefttop',
  },
})

function HeroSection({ className, variant = 'left', banner, infos, ...props }: HeroSectionProps) {
  const renderBannerMedia = (mediaType: keyof Medias, device: Device) => {
    const source = banner?.[mediaType]?.[device]
    if (!source) return null

    if (mediaType === 'images') {
      return (
        <Image
          src={source as string}
          alt={banner.description}
          objectFit="cover"
          className={banner.className}
          fill
        />
      )
    }

    if (mediaType === 'videos' && typeof source === 'object') {
      return (
        <video
          className={cn('size-full object-cover', banner.className)}
          autoPlay
          playsInline
          muted
          loop
        >
          <source src={source?.webm} type="video/webm" />
          <source src={source?.mp4} type="video/mp4" />
        </video>
      )
    }
  }

  const renderGradientOverlay = (position: Position, place: Place) => (
    <div className={heroMaskVariants({ position: position, place: place })} />
  )

  return (
    <section className={cn('lg:relative', className)} {...props}>
      <div className="lg:relative">
        <div className="relative block h-[92dvw] max-h-[700px] min-h-[360px] md:h-[700px] lg:hidden">
          {renderGradientOverlay('top', `${variant}top`)}
          {renderBannerMedia('images', 'mobile')}
          {renderBannerMedia('videos', 'mobile')}
          {renderGradientOverlay('bottom', `${variant}bottom`)}
        </div>

        <div className="relative hidden h-[680px] overflow-hidden lg:block">
          {renderGradientOverlay('top', `${variant}top`)}
          {renderBannerMedia('images', 'pc')}
          {renderBannerMedia('videos', 'pc')}
          {renderGradientOverlay('bottom', `${variant}bottom`)}
        </div>

        <div className="w-full px-[120px] 4xl:w-[2320px] 4xl:px-[calc((100vw_-_2320px)_/_2)]">
          <div className="mx-6 md:mx-16 lg:absolute lg:top-1/2 lg:mx-0 lg:w-[454px] lg:-translate-y-1/2 2xl:w-[590px]">
            <h1 className="header-2 lg:text-[64px]">{banner?.title}</h1>
            {banner?.description && (
              <p className="body-1 mt-4 lg:text-[24px] lg:font-medium lg:leading-9 lg:text-vive-white">
                {banner?.description}
              </p>
            )}
            {banner?.action && <div className="mt-3 lg:mt-6">{banner.action}</div>}
          </div>
        </div>
      </div>
      {infos && (
        <div className="no-scrollbar mt-9 w-full overflow-auto px-6 md:flex md:justify-start md:px-16 lg:mt-0 lg:justify-center lg:overflow-hidden">
          <div className="flex w-fit justify-center gap-3 lg:w-[calc(100vw_-_240px)] lg:gap-5 4xl:max-w-[2328px]">
            {infos.map((info, i) => (
              <HeroInfoCard key={`hero-cards-${i}`} {...info} />
            ))}
          </div>
        </div>
      )}
    </section>
  )
}

export default HeroSection

export interface HeroInfoCard {
  backgroundColor: 'blue' | 'purple' | 'green'
  icon: ReactNode
  title: string
  description: string
}

export interface HeroInfoCardProps extends HeroInfoCard {}

const heroBackgroundColors = {
  blue: 'bg-hero-info-card-blue',
  purple: 'bg-hero-info-card-purple',
  green: 'bg-hero-info-card-green',
}

export function HeroInfoCard({ backgroundColor, icon, title, description }: HeroInfoCardProps) {
  return (
    <div
      className={`${heroBackgroundColors[backgroundColor]} flex w-max min-w-[260px] flex-col items-center justify-start gap-3 rounded-lg px-[24px] pb-12 pt-[30px] lg:size-full lg:flex-1`}
    >
      <div className="size-12 lg:size-16">{icon}</div>
      <h2 className="header-4 text-center">{title}</h2>
      <p className="body-2 lg:body-1 max-w-[225px] text-center lg:max-w-none">{description}</p>
    </div>
  )
}
